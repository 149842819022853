
/*=============================================
=            section title            =
=============================================*/

.section-title{
    display: inline-block;
    font-size: 36px;
    color: $theme-color--default--dark;
    font-weight: 700;
    margin-top: -5px;

    @media #{$extra-small-mobile}{
        font-size: 30px;
    }
}

.grey-bg, .grey-bg--style2, .grey-bg--style3 {
    .section-title {
        color: $theme-color--default--dark
    }
}

.section-subtitle{
    font-size: 18px;
    line-height: 1.5;
    color: $theme-color--default;
    font-family: $poppins;
}

.title-icon{
    width: 4px;
    height: 20px;
    background-color: $theme-color--contrast;
    display: block;
    margin: 0 auto;
    margin-top: 15px;
}

/*=====  End of section title  ======*/

