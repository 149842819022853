/*
  Flaticon icon font: Flaticon
  Creation date: 27/04/2020 04:31
  */
@font-face {
    font-family: "Flaticon";
    src: url("../../fonts/Flaticon.eot");
    src: url("../../fonts/Flaticon.eot") format("embedded-opentype"), url("../../fonts/Flaticon.woff") format("woff"), url("../../fonts/Flaticon.ttf") format("truetype"), url("../../fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: 400;
    font-style: normal
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../../fonts/Flaticon.svg#Flaticon") format("svg")
    }
}

[class^=flaticon-]:before, [class*=" flaticon-"]:before, [class^=flaticon-]:after, [class*=" flaticon-"]:after {
    font-family: Flaticon;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.flaticon-laundry:before { content: "\f100"; }
.flaticon-book:before { content: "\f101"; }
.flaticon-jenga:before { content: "\f102"; }
.flaticon-globe:before { content: "\f103"; }
.flaticon-park:before { content: "\f104"; }
.flaticon-abacus:before { content: "\f105"; }
.flaticon-fashion:before { content: "\f106"; }
.flaticon-man-greeting:before { content: "\f107"; }
.flaticon-idea:before { content: "\f108"; }
.flaticon-child:before { content: "\f109"; }
.flaticon-kitchen:before { content: "\f10a"; }
.flaticon-cook:before { content: "\f10b"; }
.flaticon-kitchen-1:before { content: "\f10c"; }
.flaticon-toy:before { content: "\f10d"; }
.flaticon-nap:before { content: "\f10e"; }
