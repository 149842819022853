
/*=============================================
=            testimonial slider            =
=============================================*/

.testimonial-slider-area-bg{
    background-size: cover;
    background-repeat: no-repeat;
}

.testimonial-slider{

    &__container-area{
        position: relative;
        .swiper-pagination{

            &-bullets{
                position: absolute;
                right: 0;
                left: auto !important;
                top: 50% !important;
                bottom: auto !important;
                transform: translateY(-50%);
                width: auto !important;
                display: inline-block;

                @media #{$large-mobile}{
                    position: static;
                    transform: none;
                    margin-top: 30px;
                    display: block;
                }
            }

            &-bullet{
                display: block;
                margin: 0 !important;
                margin-bottom: 10px !important;
                background-color: $white;
                opacity: 1;

                &:last-child{
                    margin-bottom: 0 !important;
                    @media #{$large-mobile}{
                        margin-right: 0 !important;
                    }
                }

                @media #{$large-mobile}{
                    display: inline-block;
                    margin-bottom: 0 !important;
                    margin-right: 10px !important;
                }

                &:last-child{
                    margin-bottom: 0;
                }
                &-active{
                    background-color: $theme-color--default;
                }
            }
        }

    }


    &__single-slide{
        color: $black;
        opacity: 0 !important;
        &.swiper-slide-active{
            opacity: 1 !important;
        }
        .author{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            &__image{
                flex-basis: 150px;
                margin-right: 30px;
                width: 150px;
                height: 150px;
                img{
                    border-radius: 50%;
                    max-height: 150px;
                    max-width: 150px;
                }
            }

            &__details{
                flex-basis: calc(100% - 100px);
                .name{
                    font-size: 24px;
                    color: $white;
                    font-weight: 400;
                }

                .designation{
                    font-size: 20px;
                    font-family: $poppins;
                }

                .training{
                    font-size: 14px;
                    font-family: $poppins;
                }
            }
        }

        .content{
            font-size: 18px;
            line-height: 1.5;
            font-style: italic;
            font-weight: 300;
            width: 800px;
            max-width: 100%;

            @media #{$tablet-device}{
                width: 640px;
                margin-bottom: -5px;
            }

            @media #{$large-mobile}{
                font-size: 20px;
            }

            @media #{$extra-small-mobile}{
                font-size: 18px;
            }
        }
    }
}

/*=====  End of testimonial slider  ======*/

