

//Colours from Blue and Pink Blossoms in logo
// project specific variables
// 3FA6C7 - blue main petals
// E01459 - pink main petals
// 41A4C7 - blue outline darker
// BEE0E8 - blue outline lighter
// ECA4B8 - pink outline darker
// F2C5D4 - pink outline lighter
// 4B73AD - purple stamens
// FBB95E - yellow stamens
// 3B3632 - black bodies
// 1E1E1E - text
/*
$theme-color--pink1: #E01459; //pink main petals
$theme-color--pink2: #ECA4B8; //pink outline darker
$theme-color--pink3: #F2C5D4; //pink outline lighter
$theme-color--blue1: #3FA6C7; //blue main petals
$theme-color--blue2: #41A4C7; //blue outline darker
$theme-color--blue3: #BEE0E8; //blue outline lighter
$theme-color--yellow: #FBB95E; //yellow stamens
$theme-color--violet: #4B73AD; //purple stamens
$theme-color--black: #1E1E1E; //text
$theme-color--black2: #3B3632; //Black bodies
$theme-color--black3: #05103B;
$theme-color--black4: #313131;
*/

//Wix colour palette
//A47A82 - main
//E9DBEA - light pink
//391C1B - burgundy
//757575 - grey
//FFFFFF - white
//0A0606 - black
$theme-color--wix-main: #A47A82;
$theme-color--wix-light-pink: #E9DBEA;
$theme-color--wix-burgundy: #391C1B;
$theme-color--wix-grey: #757575;
$theme-color--wix-white: #FFFFFF;
$theme-color--wix-black: #0A0606;
$theme-color--wix-black2: #3B3632;
$theme-color--default: $theme-color--wix-main;
$theme-color--default--light: $theme-color--wix-light-pink;
$theme-color--contrast: $theme-color--wix-grey;
$theme-color--contrast--light: $theme-color--wix-grey;
$theme-color--contrast--dark: $theme-color--wix-burgundy;
$white: $theme-color--wix-white;
$black: $theme-color--wix-black;


//Pastel Colour Pallet - Version 1
$theme-color--p1--pink-main: #FACAC8;
$theme-color--p1--pink-dark: #EE8AA2;
$theme-color--p1--pink-light: #F7BBBA;
$theme-color--p1--grey-main: #868684;
$theme-color--p1--grey-dark: #9A9A98;
$theme-color--p1--grey-light: #AAAEAD;
$theme-color--p1-black: #0A0606;
$theme-color--p1-black2: #3B3632;
$theme-color--p1-white: #FFFFFF;

//Pastel Colour Pallet - Version 2
$theme-color--p1--pink-main: #FFB9B9;
$theme-color--p1--pink-dark: #EE8AA2;
$theme-color--p1--pink-light: #F4D8CA;
$theme-color--p1--grey-main: #868684;
$theme-color--p1--grey-dark: #9A9A98;
$theme-color--p1--grey-light: #AAAEAD;
$theme-color--p1-black: #0A0606;
$theme-color--p1-black2: #3B3632;
$theme-color--p1-white: #FFFFFF;


//Cherry Blossom - Colour palette #2175
$theme-color--p1--pink-main: #e3adca;
$theme-color--p1--pink-dark: #d2618c;
$theme-color--p1--pink-light: #f7e0f4;
$theme-color--p1--lavender: #dfd3eb;
$theme-color--p1--blue: #9db6c7;
$theme-color--p1--grey-main: #868684;
$theme-color--p1--grey-dark: #5D5C61;
$theme-color--p1--grey-light: #AAAEAD;

$theme-color--p1-black: $theme-color--p1--grey-dark;
$theme-color--p1-black2: $theme-color--p1--grey-main;
$theme-color--p1-white: #FFFFFF;

// Colors ---------------
$theme-color--default: $theme-color--p1--pink-main;
$theme-color--default--contrast: $theme-color--p1--blue;
$theme-color--default--light: $theme-color--p1--pink-light;
$theme-color--default--dark: $theme-color--p1--pink-dark;
$theme-color--contrast: $theme-color--p1--grey-light;
$theme-color--contrast--light: $theme-color--p1--grey-main;
$theme-color--contrast--dark: $theme-color--p1--blue;
$white: $theme-color--p1-white;
$black: $theme-color--p1--grey-dark;
$theme-color--black: $theme-color--p1--grey-dark;
$theme-color--black2: $theme-color--p1--grey-main;

$theme-color--background-testimonial: $theme-color--p1--pink-light;


// Other Colors
$primary:       $theme-color--default;
$secondary:     $theme-color--contrast;
$success:       #28a745;
$info:          #17a2b8;
$warning:       #ffc107;
$danger:        #dc3545;
$light:         #f8f9fa;

// Social Color
$android: #7AC157;
$apple: #B8B8B8;
$behance: #1869FF;
$codepen: $black;
$dribbble: #EA4C8A;
$dropbox: #007EE5;
$evernote: #78D525;
$facebook: #4867AA;
$github: #313131;
$google-drive: #1DA462;
$google-earth: #4285F4;
$google-glass: #EA4335;
$google-maps: #5083C3;
$google-play: #01B9FD;
$google-plus: #DD5144;
$google: #4285F4;
$instagram: #B23A94;
$css3: #0277BD;
$html5: #E44D26;
$javascript: #F9DC3D;
$python: #0C9DBF;
$lastfm: #E31B23;
$linkedin: #007BB6;
$paypal: #002F86;
$pinterest: #BD081B;
$pocket: #EF3E56;
$polymer: #F87292;
$rss: #F99C3A;
$share: #2C9CFF;
$stackoverflow: #F38024;
$steam: #15497B;
$twitter: #1DA1F2;
$vk: #5181B8;
$wikipedia: #E9E9E9;
$windows: #0078D6;
$s500px: $black;
$s8tracks: #122D4B;
$amazon: #F79B34;
$blogger: #F06A35;
$delicious: #0000FE;
$disqus: #2E9EFE;
$flattr: #7AB831;
$flickr: #FE0084;
$odnoklassniki: #F58220;
$outlook: #0072C6;
$playstation: #07418E;
$reddit: #FF4500;
$skype: #00A9F0;
$slideshare: #0077B5;
$soundcloud: #FE4900;
$tumblr: #36465D;
$twitch: #6441A4;
$vimeo: #1AB7EA;
$whatsapp: #189D0E;
$xbox: #107C0F;
$yahoo: #4101AF;
$youtube: #FE0000;


$transition--cubic:  all .3s cubic-bezier(.645,.045,.355,1);

// Font Family
$font-family-name: 'Roboto', sans-serif;
$heading-font-family-name: 'Roboto', sans-serif;
$poppins: 'Poppins', sans-serif;


// Body Text
$body-text-color: $black;
$body-font-weight: 400;
$body-line-height: 1.66;
$body-font-size: 15px;
$body-bg-color: $white;

// Heading Text
$heading-text-color: $black;
$heading-font-weight: 700;

// Responsive Variables
$laptop-device : 'only screen and (min-width: 1200px) and (max-width: 1499px)';
$desktop-device : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$tablet-device:'only screen and (min-width: 768px) and (max-width: 991px)';
$large-mobile:'only screen and (max-width: 767px)';
$small-mobile: 'only screen and (max-width: 575px)';
$extra-small-mobile: 'only screen and (max-width: 479px)';